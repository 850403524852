import React from 'react'
import styled from 'styled-components'
import { CustomersReviewBlock } from './CustomersReviewBlock'
import CustomerReviewSectionData from '../Data/CustomerReviewSectionData'

const AppCustomerReviewSectionWapper = styled.div`
  padding: 1rem 2rem 1rem 2rem;
  @media (max-width: 1090px) {
    padding: 1rem 1rem 1rem 1rem;
  }
  .mainContain {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1920px;
    /* margin: auto; */
    /* margin-top: -52px; */
  }
  .InternalSection {
    padding: 20px 0px 0px 0px;
    /* margin-left: -32px; */
    width: 70vw;
    height: auto;
    /* background-color: gainsboro; */
    /* background-color: #f3f4f6; */
    border: 2px solid #34a1d5;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    @media (max-width: 1090px) {
      flex-flow: wrap;
      padding: 42px 0px 0px 35px;
    }
  }
  .containHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      text-align: center;
      display: block;
      padding-bottom: 3%;
    }
  }
  .containHeadingBig {
    margin: auto;
    padding-bottom: 3%;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .CustomersReviewPara {
    margin: 0;
    text-align: center;
    line-height: 34px;
    font-size: 18px;
    max-width: 950px;
    padding: 0px 40px 10px 40px;
    @media (max-width: 1391px) {
      max-width: 100vw;
      padding: 15px 75px 65px 0px;
    }
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
      font-size: 16px;
    }
    @media (max-width: 480px) {
      line-height: 24px;
      font-size: 15px;
    }
  }
  .CustomersReviewImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: auto;
    @media (max-width: 1391px) {
      width: 60%;
    }
    @media (max-width: 1090px) {
      width: 100%;
      margin-left: 140px;
    }
    @media (max-width: 825px) {
      width: 100%;
      margin-left: 80px;
    }
    @media (max-width: 480px) {
      margin: 0;
    }
  }
  .CustomersReviewImageCenter {
    width: 15vw;
    border: 7px solid #34a1d5;
    border-radius: 220px;

    @media (max-width: 1391px) {
      border: 9px solid #34a1d5;
      border-radius: 220px;
      width: 50%;
      margin: auto;
    }
    @media (max-width: 1090px) {
      border: 9px solid #34a1d5;
      border-radius: 220px;
      width: 40%;
      margin-left: 60px;
    }
  }
  .paraSection {
    /* margin-top: 1%; */
    margin: auto;
  }
  .iconColor {
    color: #34a1d5;
    /* padding: 15px 15px 15px 0px; */
  }
  .buttonDisplay {
    display: none;
  }

  .CustomersReviewParaTwo {
    font-size: 24px;
    font-weight: bolder;
    /* margin-left: 42px; */
    color: #047cc2;
    @media (max-width: 1090px) {
      padding-right: 170px;
      color: #047cc2;
    }
    @media (max-width: 480px) {
      padding-right: 74px;
      color: #047cc2;
    }
  }
  .CustomersReviewParaThree {
    margin: 0;
    font-size: 22px;
    color: #797a7c;
    /* margin-left: 18px; */
    /* padding-bottom: 12px; */
    @media (max-width: 1090px) {
      padding-right: 170px;
    }
    @media (max-width: 480px) {
      padding-right: 74px;
    }
  }
  .ReviewCustomer-container {
    padding: 100px 0px 100px 0px;
  }
`

export const AppCustomerReviewSection = () => {
  return (
    <AppCustomerReviewSectionWapper>
      {CustomerReviewSectionData.map((data, index) => {
        return (
          <div className="ReviewCustomer-container" key={index}>
            <CustomersReviewBlock
              // Heading={data.Heading}
              paraOne={data.paraOne}
              ImagePath={data.ImagePath}
              paraTwo={data.paraTwo}
              paraThree={data.paraThree}
              altName={data.AltName}
              mainContain="mainContain"
              containHeading="containHeadingSmall"
              containHeadingBig="containHeadingBig"
              containParaOne="CustomersReviewPara"
              containParaTwo="CustomersReviewParaTwo"
              containParaThree="CustomersReviewParaThree"
              InternalSection="InternalSection"
              containImage="CustomersReviewImage"
              paraSection="paraSection"
              IconColorTwo="iconColor"
              IconColorThree="iconColor"
              IconColorOne="buttonDisplay"
              IconColorFour="buttonDisplay"
              IconColorFive="buttonDisplay"
              containParaFive="buttonDisplay"
              containParaFour="buttonDisplay"
              ButtonDisplay="buttonDisplay"
              allImage="CustomersReviewImageCenter"
            />
          </div>
        )
      })}
    </AppCustomerReviewSectionWapper>
  )
}
