export default [
  {
    id: '1',
    // Heading: 'Why Now ?',
    paraOne:
      '“Raindrop’s leadership team of experienced procurement professionals have built a truly disruptive enterprise spend management solution with the end user in mind.  Raindrop’s impressive speed to value attainment, ease of use, and intuitive and clean user interface are true differentiators that deserve strong consideration.”',
    paraTwo: 'Patrick Reymann',
    paraThree: 'Research Director, IDC',
    ImagePath: '/img/Patrick-Reymann.jpg',
    AltName: 'Patrick Customer',
  },
]
